.static_content{
    padding: 20px;
    height: 420px;
    overflow: auto;
    font-size: 12px;
}
.static_title{
    font-family: DMSans;
    font-size: 29px;
    font-weight: bold;
    letter-spacing: 1.8px;
    margin-bottom: 15px;
}
.static_content::-webkit-scrollbar {
    width: 8px;
}

.static_content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.static_content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #ff470b;
}
.home-logo{
    width: 260px;
    height: 220px;
    object-fit: contain;
}
.title{
    width:60%;
    font-family: DMSans;
    font-size: 29px;
    font-weight: bold;
    /* line-height: 0; */
    letter-spacing: 1.8px;
    text-align: left;
    color: #ffffff;  
}
.bigger_font{
    font-family: DMSans;
    font-size: 25px;
}
.content{
    width:60%;
    font-family: DMSans;
    font-size: 20px;
    line-height: 1.3;
    text-align: left;
    color: #ffffff;  
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    /* overflow: hidden; */
}
.download_content{
    font-family: DMSans;
    font-size: 15px;
    line-height: 1.3;
    text-align: left;
    color: #ffffff;  
}
.submit_notify{
    width: 138px;
    height: 48px;
    box-shadow: 3px 7px 8px 0 rgba(103, 83, 47, 0.49);
    background-image: linear-gradient(300deg, #d40000, #d40000, #ffb900, #ffb900);
    border: none;  
}
.bordered_box{
    width: 145px;
    height: 45px;
    border: solid 1px #707070;
    border-radius: 10px;  
    cursor: pointer;
    margin-left: 2px;
}
.download_app{
    width: 17.9px;
    height: 20.8px;
    object-fit: contain;  
    margin-top: 8px;
    margin-left: 5px;
}
.download_on{
    font-family: DMSans;
    font-size: 12px;
    letter-spacing: 0.3px;
    text-align: right;
    color: #ffffff;  
}
.store{
    font-family: DMSans;
    font-size: 14px;
    text-align: right;
    color: #ffffff;  
}
.full_width{
    width: 100%;
}

.color_yellow{
    color: #faa003;
}

.color_red{
    color: #d50200;
}

.color_orange{
    color: #ff470b;
}
/* media rules for responsiveness */
@media screen and (max-width: 600px) {
    .home-logo{
        width: 230px;
        height: 110px;
        object-fit: contain;
    }
    .title{
        width:100%;
        text-align: center;
        color: #ffffff;  
    }
    .bigger_font{
        font-family: DMSans;
        font-size: 20px;
    }
    .content{
        width:100%;
        font-size: 15px;
    }
}
