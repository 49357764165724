.container_div{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.footer_content{
    display: flex;
}
.follow_img{
    width: 20px;
    height: 18px;
    object-fit: contain;  
}
.follow_links_title{
    font-family: DMSans;
    line-height: 2.5;
    /* font-size: 10px; */
    letter-spacing: 0.48px;
    text-align: left;
    color: #ffffff; 
    margin-right: 5px;
    margin-left: 5px; 
}
#display_footer{
    display: none;
}
#margin_bottom{
    margin-bottom: 15px;
}
.remove_margin{
    margin-left: 8px;
}
.no_margin{
    margin-left: 0;
}
#remove_underline{
    text-decoration: none;
}
@media screen and (max-width: 600px) {
    .container_div{
        width: 100%;
        display: block;
        margin-top: -40px;
    }
    .footer_content{
        display: flex;
        justify-content: center;
    }
    .follow_img{
        width: 12px;
        height: 15px;
        object-fit: contain;  
    }
    .follow_links_title{
        font-family: DMSans;
        font-size: 10px;
        line-height: 2.5;
        letter-spacing: 0.48px;
        text-align: left;
        color: #ffffff;  
    }    
    .download_on{
        font-family: DMSans;
        font-size: 8px;
        letter-spacing: 0.3px;
        text-align: right;
        color: #ffffff;  
    }
    .store{
        font-family: DMSans;
        font-size: 10px;
        text-align: right;
        color: #ffffff;  
    }    
    .bordered_box{
        width: 100px;
        height: 30px;
        border: solid 1px #707070;
        border-radius: 10px;  
        cursor: pointer;
    }
    .download_app{
        width: 15px;
        height: 15px;
        margin-top: 5px;
        margin-left: 5px;
        object-fit: contain;  
    }    
}
@media screen and (min-width: 768px) {
    .container_div{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .footer_content{
        display: flex;
    }
    .follow_links_title{
        font-family: DMSans;
        font-size: 10px;
        line-height: 2.5;
        letter-spacing: 0.48px;
        text-align: left;
        color: #ffffff;  
    }    
}
@media screen and (min-width: 992px) {
    .container_div{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .footer_content{
        display: flex;
    }
    .follow_links_title{
        font-family: DMSans;
        font-size: 14px;
        line-height: 2.5;
        letter-spacing: 0.48px;
        text-align: left;
        color: #ffffff;  
    }    
}
@media only screen and (min-width: 576px) and (max-width: 767px){
    .container_div{
        width: 100%;
        display: block;
        margin-top: -40px;
    }
    .footer_content{
        display: flex;
        justify-content: center;
    }
}
@media only screen and (max-width: 575px){
    .container_div{
        width: 100%;
        display: block;
        margin-top: -40px;
    }
    .footer_content{
        display: flex;
        text-align: center;
    }
}