#infoMenu{
  height: 60px;
  min-height: 80px;
  color: #f2f2f2;
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: 0.7em;
  padding: 8px 0 8px 0;
}
@media screen and (max-width: 600px) {
  #infoMenu{
    height: 70px;
    min-height: 80px;
    position: fixed;
    z-index: 70;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 0.7em;
    padding: 8px 0 8px 0;
  }  
}
@media screen and (min-width: 768px) {
  #infoMenu{
    height: 70px;
    min-height: 95px;
  }
}
@media screen and (min-width: 992px) {
  #infoMenu{
    height: 60px;
    min-height: 80px;
  }
}

