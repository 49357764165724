.quote_img{
    width: 300px;
    height: 450px;
    object-fit: contain;  
}
.quote_title{
    width: 60%;
    font-family: DMSans;
    font-size: 29px;
    line-height: 1.31;
    letter-spacing: 0.87px;
    text-align: left;
    color: #ffffff;  
}
.quote_content{
    width: 60%;
    font-family: DMSans;
    font-size: 20px;
    line-height: 1.3;
    text-align: left;
    color: #ffffff;
}
.align_center{
    align-self: center;
}

@media screen and (max-width: 600px) {
    .quote_img{
        width: 200px;
        height: 250px;
        object-fit: contain;  
    }
    .quote_title{
        width: 100%;
        font-family: DMSans;
        font-size: 20px;
        line-height: 2;
        text-align: center;
    }
    .quote_content{
        width: 100%;
        font-family: DMSans;
        font-size: 15px;
        text-align: center;
    }
}