.logo{
    width: 75px;
    height: 75px;
}
.contact_title{
    font-family: DMSans;
    font-size: 30px;
    letter-spacing: 2px;
    color: #ffffff;
}
.align_self{
    align-self: center;
}
#align_center{
    text-align: center;
}
.form_background{
    opacity: 0.4;
    border-radius: 2px;
    border: solid 1px #707070;
    background-color: #000000;
  }
.form_background::placeholder{
    color: #ffffff;
}
.submit_form{
    width: 200px;
    height: 54px;
    box-shadow: 3px 7px 8px 0 rgba(103, 83, 47, 0.49);
    background-image: linear-gradient(290deg, #d40000, #d40000, #ffb900, #ffb900);
    border: none;
}
.full_width{
    width: 100%;
}

@media screen and (max-width: 600px) {
    .contact_title{
        font-size: 20px;
    }
    .submit_form{
        width: 100px;
        height: 44px;
    }        
}