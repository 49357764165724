/*Overrides must match specificity of pre-loaded styles!*/
/*The simplest way to get the selector is with devtools*/
#fp-nav ul li:hover a.active span,
#fp-nav ul li a.active span,
.fp-slidesNav ul li:hover a.active span,
.fp-slidesNav ul li a.active span {
  background-color: #ff471c !important;
}
#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
    background: #fff !important;
}
